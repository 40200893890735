import {ajax} from "../../library/ajax";
import {showShoppingCart} from "./product-shopping-cart";
import {sendPopupEvent, trackEvent} from "../../library/analytics";
import {getDeviceType} from "../../library/device-type";

document.addEventListener('click', function(e) {
    if (e.target.id === 'approve-pop-up-smarter-click') {
        let popUp = document.getElementById('pop-up-smarter-click').value;
        if (popUp === 'POP UP EMAIL') {
            let privacyElement = document.getElementById('pop-up-smarter-click-privacy');
            let email = document.getElementById('pop-up-smarter-click-email');

            if (email.value === '') {
                email.classList.add('error');
                return;
            }

            if (!privacyElement.checked) {
                privacyElement.classList.add('error');
                return;
            }

            ajax.post(
                '/ajax/pop-ups/smarter-click/send-email',
                {
                    email: email.value
                }
            );
            $("#modal-pop-up-smarter-click").modal('hide');
        } else {
            $("#modal-pop-up-smarter-click").modal('hide');
            let voucherCode = document.getElementById('pop-up-smarter-click-voucher-code').value;
            ajax.post(
                '/ajax/shopping/shopping-cart-check-voucher',
                {
                    code: voucherCode
                },
                function() {
                    showShoppingCart();
                },
            );
        }
        //trackEvent('pop-up', 'exit-page', 'approve');
        let popupAction = e.target.dataset.popupType || 'pop-up';
        trackEvent('Popup', popupAction, 'approve');
        sendPopupEvent('popup_discount', popupAction, 'approve');
    } else if (e.target.classList && e.target.classList.contains('close-pop-up-smarter-click')) {
        //trackEvent('pop-up', 'exit-page', 'cancel');
        let popupAction = e.target.dataset.popupType || 'pop-up';
        trackEvent('Popup', popupAction, 'cancel');
        sendPopupEvent('popup_discount', popupAction, 'cancel');
    }
});

function idleUser() {
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeydown = resetTimer;
    window.addEventListener('scroll', resetTimer, true);

    function popupFunction() {
        showPopUp();
        leaveDetected = true;
    }

    function resetTimer() {
        clearTimeout(t);
        if (!leaveDetected) {
            t = setTimeout(popupFunction, 180000);  // time is in milliseconds. 3 minutes
        }
    }
}

// https://loader.wisepops.com/get-loader.js?v=1&site=QaDYcWQGG2
function exitIntent(e) {
    console.log("location: " + document.location + ", state: " + JSON.stringify(e.state));
    //console.log("EXIT: " + JSON.stringify(e.state.exitpops));
    if (e.state && 'exit-intent' === e.state.exitpops) {
        setTimeout(function (){
            showPopUp();
            leaveDetected = true;
            if (window.history.state) {
                window.history.replaceState({exitpops: 'normal-intent'}, '');
                //window.history.back();
            }
        }, 100);
        return false;
    }
}

function exitIntentBeforeUnload(e) {
    if (!leaveDetected) {
        e.stopPropagation();
        e.preventDefault();
        window.history.back();
        return false;
    }
}

var leaveDetected = false;
$(document).ready(function () {
    let device = getDeviceType();

    setTimeout(function () {
        document.addEventListener('mouseleave', function(event) {
            showDesktopPopUp(event);
        });
        if (device === 'tablet' || device === 'mobile') {
            idleUser();
            window.addEventListener('blur', function(){
                showPopUp();
            });
        };
    }, 30000); // wait 30s before show any popup for the first time

    $('body').on('hidden.bs.modal', '#modal-pop-up-smarter-click', function() {
        $(this).remove();
    });

    $('body').on('shown.bs.modal', '#modal-pop-up-smarter-click', function() {
        $.material.init();
    });
});

function showPopUp()
{
    let cookieChoice = getCookie('_tv_cookie_choice');
    if (cookieChoice !== 'ALL' && cookieChoice !== 'BASIC') {
        return;
    }
    if ($("#modal-pop-up-smarter-click").length) {
        return;
    }

    let cookie = getCookie('_tv_cookie_pop_up_smarter_click');
    if (cookie === '') {
        ajax.post(
            '/ajax/pop-ups/smarter-click/render',
            {},
            function(data) {
                let popUp = data.popUp;
                if (popUp) {
                    $("#modal-pop-up-smarter-click").modal('hide');
                    $(popUp).modal();
                    //trackEvent('pop-up', 'exit-page', 'show');
                    trackEvent('Popup', data.popupType, 'show');
                    sendPopupEvent('popup_discount', data.popupType, 'show');
                }
            },
        );
    }
}

function showDesktopPopUp(event)
{
    let windowHeight = window.innerHeight;
    if (event.offsetY < 0 || event.offsetY >= windowHeight) {
        showPopUp();
    }
}