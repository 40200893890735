import {trackEvent} from "../../library/analytics";

$(document).ready(function() {
    $(document).on('click', '.description-more', function(event) {
        let description = $(this).parent();
        description.removeClass('description-closed').addClass('description-open');
        updateDescriptionButtons(description);
    });
    $(document).on('click', '.description-less', function(event) {
        let description = $(this).parent();
        description.removeClass('description-open').addClass('description-closed');
        updateDescriptionButtons(description);
    });
    $(document).on('click', '#listing-container .description-btn', function() {
        trackEvent('ProductList', 'Description', 'open/close');
    });

    initializeDescription();
});

function updateDescriptionButtons(element) {
    element.find('.description-btn').remove();

    let buttonText = element.data('view-more-text');
    let buttonClass = 'description-more';
    if (element.hasClass('description-open')) {
        buttonText = element.data('view-less-text');
        buttonClass = 'description-less';
    }

    element.append(
        '<span class="description-btn ' + buttonClass + '">' + buttonText + '</span>'
    );
}

export function initializeDescription() {
    $('.description-content').each(function () {
        let element = $(this);
        let lines = element.height() / parseInt(element.css('line-height'));
        let maxLines = element.data('max-lines');

        if (lines > maxLines) {
            updateDescriptionButtons(element);
        }
    });
}