import {addToWishlist} from "../../library/wishlist";
import {ecSendData, trackEvent, trackLinkEvent} from "../../library/analytics";
import Swiper from "swiper/js/swiper";
import {productClick, viewProducts} from "../../website/webpack/modules/algolia-insights";
import {onVisible} from "../../website/scripts/Tools/visible-element-observer";
import {itemDatalayerPush} from "../../website/webpack/modules/analytics";
import {getLastViewedProducts} from "../../website/webpack/modules/last-viewed-products";

$(document).ready(function() {
    //sendAlgoliaProductView();

    $(document).on('click', '.related-container .list-product', function(e){
        e.stopPropagation();
        let id = $(this).find('input[name="id"]').val();
        productClick('Related', 'Click Product', id);
        let link = $(this).find('a');
        let name = link.text();
        let listName = $('#listName').length ? $('#listName').val() + ' - ' : 'Product Page - ';
        let refCode = $(this).find('input[name="reference"]').val();
        let parent = $(this).parents('.related-container').first();
        let type = parent.data('type') ? parent.data('type') : '';
        let list = listName + 'Related Products - ' + type;
        ecSendData(refCode, name, null, null, null, null, null, 'click', {list: list});
        trackLinkEvent('Related', 'click', name, link, e);
    });

    $(document).on('click', '.search-related-container .list-product', function(e){
        e.stopPropagation();
        let link = $(this).find('a');
        trackLinkEvent('SearchRelated', 'click', link.text(), link, e);
    });

    $(document).on('click', '.related-container .wishlist', function(e){
        e.stopPropagation();
        let form = $(this).find('.wishlist-icon-form');
        addToWishlist(form);
        trackEvent('Related', 'WishList', $(this).siblings('title').text());
    });

    $(document).on('click', '.related-container .related-nav', function() {
        trackEvent('Related', 'ShowMoreProducts', '');
    })

    $(document).on('click', '.search-related-container .related-nav', function() {
        trackEvent('SearchRelated', 'ShowMoreProducts', '');
    })

    bestSellersContent = '';
    loadRelatedProducts();
    onVisible(document.querySelectorAll(".last-viewed-products-container"), function (element) {
        initializeLastViewProducts();
    });
});

let algoliaProductsViewed = [];
function sendAlgoliaProductView()
{
    let ids = [];
    $('.related-container .list-product').each(function() {
        let product = $(this);
        let id = product.find('input[name="id"]').val();
        if (!algoliaProductsViewed.includes(id)) {
            algoliaProductsViewed.push(id);
            ids.push(id);

            if (ids.length == 20) {
                viewProducts('Related', 'View Product', ids);
                ids = [];
            }
        }
    });

    if (ids.length) {
        viewProducts('Related', 'View Product', ids);
    }
}

var relatedSwiperInstances = [];
export var bestSellersContent = '';
export function loadRelatedProducts($container) {
    /*if (!window.showRelatedProducts) {
        return;
    }*/
    let emptyContainer = (typeof $container === 'undefined');
    $container = $container || $('body');
    $container.find('.related-container, .search-related-container').each(function() {
        let container = $(this);
        if (emptyContainer && container.hasClass('search-related-container')) {
            return;
        }
        let type = container.data('type') || null;
        let excluded = container.data('excluded') || null;
        let categoryName = container.data('categoryname') || null;
        let productType = container.data('producttype') || null;
        let categories = container.data('categories') || null;
        let appVersion = container.data('appversion') || null;
        if (type && container.is(':empty')) {
            $.post(
                '/ajax/related-products/load',
                {
                    type: type,
                    pathname: location.pathname,
                    excluded: excluded,
                    categoryName: categoryName,
                    productType: productType,
                    categories: categories,
                    appVersion: appVersion,
                },
                function(data) {
                    if (data.relatedProducts) {
                        if (data.relatedProducts.multiContainer) {
                            for (var key in data.relatedProducts.multiContainer) {
                                if (data.relatedProducts.multiContainer.hasOwnProperty(key)) {
                                    if (container.hasClass('search-related-container')) {
                                        if (key === 'search-best-sellers' || key === 'search-similar-products') {
                                            bestSellersContent = data.relatedProducts.multiContainer[key] || '';
                                        }
                                        container = $('.search-related-container[data-content="' + key + '"]');
                                    } else {
                                        if (key === 'best-sellers' || key === 'similar-products') {
                                            bestSellersContent = data.relatedProducts.multiContainer[key] || '';
                                        }
                                        container = $('.related-container[data-content="' + key + '"]');
                                    }
                                    container.html(data.relatedProducts.multiContainer[key]).initializeSlider(20);
                                }
                            }
                        } else {
                            container.html(data.relatedProducts).initializeSlider(20);
                        }
                        //sendAlgoliaProductView();
                        onVisible(document.querySelectorAll(".fire-product-datalayer"), (element) => itemDatalayerPush('view_item_list', element));
                    }
                }
            );
        }
    });
}

export function initializeLastViewProducts() {
    let container = $('.last-viewed-products-container');
    $.post(
        '/ajax/website/product-list/get-last-viewed-products-recommender',
        {
            oldView: true,
            lastViewedProducts: getLastViewedProducts()
        },
        function(data) {
            if (data.recommender) {
                container.html(data.recommender).initializeSlider(20);
                container.removeClass('last-viewed-products-container');
            } else {
                container.remove();
            }
        }
    );
}

(function($) {
    $.fn.initializeSlider = function(threshold, callback) {
        let $w = $(window),
            th = threshold || 0,
            container = this,
            loaded;

        this.one("initializeSlider", function() {
            let list = $(this).find('.related-items');
            let smallContainer = $(this).hasClass('related-items-small');

            let index = relatedSwiperInstances.length;
            if (list.length > 0) { //some-slider-wrap-in
                list.each(function(){ //some-slider-wrap-in
                    var $this = $(this);
                    $this.addClass("related-instance-" + index); //instance need to be unique (ex: some-slider)
                    $this.parent().find(".swiper-button-prev").addClass("related-prev-" + index); //prev must be unique (ex: some-slider-prev)
                    $this.parent().find(".swiper-button-next").addClass("related-next-" + index); //next must be unique (ex: some-slider-next)
                    relatedSwiperInstances[index] = new Swiper(".related-instance-" + index, { //instance need to be unique (ex: some-slider)
                        loop: true,
                        slidesPerGroup: 3,
                        slidesPerView: 3,
                        // Disable preloading of all images
                        preloadImages: false,
                        // Enable lazy loading
                        lazy: {
                            loadOnTransitionStart: true,
                        },
                        watchOverflow: true,
                        breakpoints: {
                            1200: {
                                slidesPerView: smallContainer ? 3 : 5,
                                slidesPerGroup: smallContainer ? 3 : 5
                            },
                            992: {
                                slidesPerView: smallContainer ? 3 : 5,
                                slidesPerGroup: smallContainer ? 3 : 5
                            },
                            768: {
                                slidesPerView: smallContainer ? 3 : 5,
                                slidesPerGroup: smallContainer ? 3 : 5
                            }
                        },
                        navigation: {
                            prevEl: ".related-prev-" + index,  //prev must be unique (ex: some-slider-prev)
                            nextEl: ".related-next-" + index, //next must be unique (ex: some-slider-next)
                        },
                    });

                    index++;
                });

                // Now you can call the update on a specific instance in the "relatedSwiperInstances" object
                setTimeout(function () {
                    for (var slider of relatedSwiperInstances) {
                        slider.update();
                        slider.slides.each(function(){
                            $(this).find('img.lazy').unveil().removeClass('lazy');
                        });
                    }
                }, 50);
            }
            if (typeof callback === "function") callback.call(this);
        });

        function initializeSlider() {
            let inview = container.filter(function() {
                let $e = $(this);
                if ($e.is(":hidden")) return;

                let wt = $w.scrollTop(),
                    wb = wt + $w.height(),
                    et = $e.offset().top,
                    eb = et + $e.height();

                return eb >= wt - th && et <= wb + th;
            });

            loaded = inview.trigger("initializeSlider");
            container = container.not(loaded);
        }

        $w.on("scroll.initializeSlider resize.initializeSlider lookup.initializeSlider", initializeSlider);

        initializeSlider();

        return this;

    };

})(window.jQuery);