export function addToWishlist(form){
    $.post(
        $(form).attr('action'),
        $(form).serialize(),
        function(data){
            if (data.done) {
                //TODO UPDATE WHEN LISTS ARE UPDATED
                if (data.action == 'add') {
                    $(form).find('.btnWishList').css('color', '#f44336').removeClass('social-share-wish').addClass('social-share-wish-in');
                    $(form).find('.wishlist-icon').removeClass('glyphicon-heart-empty').addClass('glyphicon-heart').addClass('in-list');
                } else {
                    $(form).find('.btnWishList').css('color', 'inherit').removeClass('social-share-wish-in').addClass('social-share-wish');
                    $(form).find('.wishlist-icon').removeClass('glyphicon-heart').removeClass('in-list').addClass('glyphicon-heart-empty');
                }
            }
            let dialog =
                "<div id='info-dialog' class='modal fade' tabindex='-1'>" +
                "<div class='modal-dialog'>" +
                "<div class='modal-content'>" +
                "<div class='modal-body'>" +
                data.message +
                "</div></div></div></div>";
            $(dialog).modal();
        },
        'json'
    );
}
// TODO - DEPRECATED: remove after stickers migration
export function checkWishListStickers() {
    let url = '/ajax/users/get-wishlist-ids';
    if (url != "undefined") {
        $.post(
            url,
            '',
            function (data) {
                //TODO REMOVE WHEN LISTS ARE UPDATED
                $("form[name^='wishlist-']").each(function () {
                    let id = parseInt($(this).find("input[name='stickerToAdd']").val());
                    if ($.inArray(id, data.ids) >= 0) {
                        $(this).find('.btnWishList').css('color', '#f44336').removeClass('social-share-wish').addClass('social-share-wish-in');
                    }
                });
                $("form.wishlist-icon-form").each(function () {
                    let id = parseInt($(this).find("input[name='stickerToAdd']").val());
                    if ($.inArray(id, data.ids) >= 0) {
                        $(this).find('.wishlist-icon').removeClass('glyphicon-heart-empty').addClass('glyphicon-heart').addClass('in-list');
                    }
                });
            },
            'json'
        );
    }
}

export function checkWishListProducts() {
    $.post(
        '/ajax/users/get-wishlist-product-ids',
        '',
        function (data) {
            $("form.wishlist-icon-form").each(function () {
                let id = parseInt($(this).find("input[name='productToAdd']").val());
                if ($.inArray(id, data.ids) >= 0) {
                    $(this).find('.wishlist-icon').removeClass('glyphicon-heart-empty').addClass('glyphicon-heart').addClass('in-list');
                    $(this).find('.btnWishList').css('color', '#f44336').removeClass('social-share-wish').addClass('social-share-wish-in');
                }
            });
        },
        'json'
    );
}