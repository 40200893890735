import {trackEvent} from "../../library/analytics";
import {addToCartFunctions, roundToMultiple, roundToMultipleOfFive} from "./add-to-cart-functions";
import {waitingDialog} from "../../library/waiting-dialog";
import {uploadImage} from "./upload-image";
import {popupInfoDialog} from "../../library/info-dialog";

let cartFunctions; // This variable is defined on loadAddToCartOptions() function (only in product-list.js)

$(document).ready(function() {
    let addToCartComponent = $('.product-popup-options .add-to-cart-component'),
        priceBoxHeight = addToCartComponent.height(),
        initialPosition = 0;

    let timeOutCustomSize;

    function scroll() {
        if (addToCartComponent.height()) {
            let currentBottomPosition = $(window).height() + $(window).scrollTop();
            let origOffsetY = cartFunctions.isMobileDevice() ? 1 : addToCartComponent.offset().top + priceBoxHeight;
            if (cartFunctions.isMobileDevice() && addToCartComponent.hasClass('fixable-add-to-cart')) {
                if (currentBottomPosition >= origOffsetY && initialPosition === 0) {
                    /*addToCartComponent.addClass('fixed-add-to-cart-component');*/
                    initialPosition = origOffsetY;
                } else if (currentBottomPosition < initialPosition || currentBottomPosition === $(window).height()) {
                    /*addToCartComponent.removeClass('fixed-add-to-cart-component');*/
                    initialPosition = 0;
                }
            }
        }
    }
    document.onscroll = scroll;

    $(document).on("click", ".product-popup-options .qty-btn-remove", function () {
        let btn = $(this);
        let input = btn.siblings('.qty-display');
        let quantity = parseInt(input.val()) - 1;
        cartFunctions.validateQuantityInput(input, quantity);
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (!isNewSearcherPopUp) {
            trackEvent('ProductList', 'ProductQuantity', 'add/remove');
        }
    });

    $(document).on("click", ".product-popup-options .qty-btn-add", function () {
        let btn = $(this);
        let input = btn.siblings('.qty-display');
        let quantity = parseInt(input.val()) + 1;
        cartFunctions.validateQuantityInput(input, quantity);
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (!isNewSearcherPopUp) {
            trackEvent('ProductList', 'ProductQuantity', 'add/remove');
        }
    });

    $(document).on("keydown", ".product-popup-options .qty-display", function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode == 65 && e.ctrlKey === true) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });

    $(document).on('click', '.product-popup-options .sticker-color-select', function () {
        let productIndex = "";
        if ($(this).closest('.multi-product-options').data('productIndex')) {
            productIndex = $(this).closest('.multi-product-options').data('productIndex').toString();
        }
        let btn = $(this);
        let panel = $(this).closest('.slider-section');
        let clone = $(this).clone();

        $(this).parent().find('.sticker-color-select').removeClass('color-selected');
        btn.addClass('color-selected');
        $('.product-popup-options #color'+productIndex).val(btn.attr('data-id'));
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');

        if (!isNewSearcherPopUp) {
            if ($('.modify-product').length > 0) {
                trackEvent('ModifyProduct', 'Color', $(this).attr('title'));
            } else {
                trackEvent('ProductList', 'Color', $(this).attr('title'));
            }
        }

        let selectedContainer = $('<div class="cart-value-selected"></div>').append(clone);
        updateClosePanel(panel, selectedContainer);
        cartFunctions.updateSizesPrices();
        let container = $(this).closest('.modify-product');
        let other = container.find('#add-to-cart-other');
        if (btn.attr('data-id') == 104) {
            other.find('#popup-orientation-normal').prop('checked', true);
            other.css('display', 'none');
        } else if (other.css('display') == 'none') {
            other.css('display', 'block');
        }
    });

    $('body').on('click', '.product-popup-options .material-color-select', function () {
        let productIndex = "";
        if ($(this).closest('.multi-product-options').data('productIndex')) {
            productIndex = $(this).closest('.multi-product-options').data('productIndex').toString();
        }
        let btn = $(this);
        let panel = $(this).closest('.slider-section');
        let clone = $(this).clone().removeClass('material-color-selected');
        $(this).parent().find('.material-color-select').removeClass('material-color-selected');
        btn.addClass('material-color-selected');
        $('.product-popup-options #materialColor'+productIndex).val(btn.attr('data-id'));
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (!isNewSearcherPopUp) {
            trackEvent('ProductPage', 'Material Color', $(this).attr('title'));
        }

        let selectedContainer = $('<div class="cart-value-selected"></div>').append(clone);
        updateClosePanel(panel, selectedContainer);
    });

    $('body').on('change', '.product-popup-options input[name=popup-model-option]', function () {
        $('.product-popup-options #model').val($(this).val());
        loadModelSizesAndColors(cartFunctions, '');

        //let selectedContainer = $('<div class="cart-value-selected"></div>').append(clone);
        //updateClosePanel(panel, selectedContainer);
    });

    $('body').on('change', '.product-popup-options input[name=popup-modelSize-option]', function () {
        $('.product-popup-options #modelSize').val($(this).val());

        //let selectedContainer = $('<div class="cart-value-selected"></div>').append(clone);
        //updateClosePanel(panel, selectedContainer);
    });

    $('body').on('change', '.product-popup-options input[name=popup-modelGender-option]', function () {
        $('.product-popup-options #modelGender').val($(this).val());
        loadModelGenderOptions(cartFunctions, '');
    });

    $('body').on('change', '.product-popup-options input[name=model-option]', function () {
        $('.product-popup-options #model').val($(this).val());
        //displaySelectedModel();
        loadModelSizesAndColors(cartFunctions, '');
    });

    $(document).on("click", ".product-popup-options .btn-add-to-cart", function () {
        let ready = $("#add-to-cart-size").data("ready");
        if (ready) {
            //cartFunctions.addToCart();
            let crossSellingProducts = [];
            $('.cross-selling-select-product:checked').each(function() {
                let product = $(this);
                let options = {
                    'width': product.data('width'),
                    'height': product.data('height'),
                    'device': product.data('device'),
                    'composition': product.data('composition'),
                    'originalProduct': $('#productId').val(),
                    'personalizedImage': product.data('image'),
                    'color': product.data('color'),
                };
                if (product.data('material-color')) {
                    options['materialColor'] = product.data('material-color');
                }
                if (product.data('model')) {
                    options['model'] = product.data('model');
                }
                if (product.data('pack')) {
                    options['pack'] = product.data('pack');
                }
                crossSellingProducts.push(
                    {
                        'id': product.data('id'),
                        'options': options,
                        'locked': product.data('locked'),
                        'linkToParent': product.data('link-to-parent')
                    }
                );
            });
            cartFunctions.addToCart(crossSellingProducts);
            $('body').css("overflow","");
        }
    });


    $(document).on('change', '.quantityAddToCart', function () {
        let quantity = $(this).val();

        $('.quantityAddToCart').val(quantity);
        if (quantity > 1) {
            $('.qty-btn-remove').attr('disabled', false);
        } else {
            $('.qty-btn-remove').attr('disabled', true);
        }
    });

    $(document).on("change", ".product-popup-options .qty-display", function () {
        if($(this).parents('.mechanism-control').length || $(this).parents('.mechanism-charger').length) {
            if (isNaN(parseInt($(this).val())) || parseInt($(this).val()) < 0 || $(this).val() === '') {
                $(this).val(0);
            }

            if($('#custom-width').val() && $('#custom-height').val()) {
                cartFunctions.updatePrice();
            }
        } else {
            if (isNaN(parseInt($(this).val())) || parseInt($(this).val()) < 1 || $(this).val() === '') {
                $(this).val('1');
            }

            cartFunctions.updatePrice();
        }
    });

    $(document).on('change', '.product-popup-options input[name=add-to-cart-size]', function () {
        let customWidth = $('.product-popup-options #custom-width');
        let customHeight = $('.product-popup-options #custom-height');
        if (customWidth.length && customHeight.length) {
            customWidth.val('');
            customHeight.val('');
        }
        cartFunctions.displaySelectedSize();
        let compositionId = $(this).data('compositionId');
        if (compositionId) {
            setPreviewImage(true, compositionId);
        }
    });

    $(document).on('change', '.product-popup-options input[name=add-to-cart-pack]', function () {
        cartFunctions.displaySelectedPack();
        cartFunctions.updateSizesPrices();
    });

    $(document).on('change', '.product-popup-options input[name=add-to-cart-customPack]', function () {
        let input = $(this);
        let value = Math.ceil(input.val());
        input.val(value);

        cartFunctions.displaySelectedPack();
    });
    $(document).on('blur click', '.product-popup-options input[name=add-to-cart-customPack]', function () {
        let radio = $('.product-popup-options #pack-custom');
        if (!radio.prop('checked')) {
            radio.prop('checked', true).trigger("change");
        }
    });

    $(document).on("keydown", ".product-popup-options #sizeWidthPers, .product-popup-options #custom-width, .product-popup-options #custom-height", function () {
        $("#add-to-cart-size").data("ready", false);
        clearTimeout(timeOutCustomSize);
        timeOutCustomSize = setTimeout(function(){
            cartFunctions.displaySelectedSize();
        }, 1000);
    });
    $(document).on("change", ".product-popup-options #sizeWidthPers, .product-popup-options #custom-width, .product-popup-options #custom-height", function () {
        $("#add-to-cart-size").data("ready", false);
        clearTimeout(timeOutCustomSize);
        timeOutCustomSize = setTimeout(function(){
            cartFunctions.displaySelectedSize();
        }, 1000);
    });
    $(document).on('focus', '.product-popup-options #custom-width, .product-popup-options #custom-height', function () {
        $('input:radio[name=add-to-cart-size]').each(function () {
            $(this).prop('checked', false);
        });

        $('.product-popup-options #productSize').val('');
        $('.product-popup-options #productSize').data('sizeid', '');
        $('.product-popup-options #productSize').data('width', '');
        $('.product-popup-options #productSize').data('height', '');
        $('.product-popup-options #productSize').data('device', '');
        $('.product-popup-options #productSize').data('composition', '');
    });
    $(document).on('blur', '.product-popup-options #custom-width, .product-popup-options #custom-height', function () {
        let multiple = $(this).data('multiple');
        if (multiple) {
            roundToMultiple(multiple, $(this));
        }
        let productId = $('.product-popup-options #productId').val();
        let width = $('.product-popup-options #custom-width').val();
        let height = $('.product-popup-options #custom-height').val();
        $.post(
            '/ajax/product/check-max-size',
            {
                productId: productId,
                width: width,
                height: height,
            },
            function (data) {
                if (!data.sizeOk && data.message && data.message.length) {
                    popupInfoDialog(data.message);
                }
            },
            'json'
        );
    });

    $(document).on('click', '.product-popup-options .btn-modify-cart-item', function() {
        cartFunctions.updateCartItem();
    });

    $(document).on('change', '.product-popup-options input[name="reversed"]', function () {
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        if (!isNewSearcherPopUp) {
            if ($(this).is(':checked')) {
                trackEvent('ProductList', 'GlassReversed', 'add');
            } else {
                trackEvent('ProductList', 'GlassReversed', 'remove');
            }
        }
        cartFunctions.updatePrice();
    });

    $(document).on('blur click', '.product-popup-options #sizeWidthPers', function () {
        let radio = $('#popup-rate-pers');
        if (!radio.prop('checked')) {
            radio.prop('checked', true).trigger("change");
        }
    });

    $(document).on('keyup', '.product-popup-options #personalizedText', function (event) {
        if ($('.product-popup-options input[name="customPriceIncrement"]').length) {
            $('.product-popup-options input[name="customPriceIncrement"]').prop('checked', true);
        }
    });

    $(document).on('change', '.product-popup-options input[name="customPriceIncrement"]', function (event) {
        if ($('.product-popup-options input[name="personalizedText"]').length) {
            $('.product-popup-options input[name="personalizedText"]').val('');
        }
        if ($('.product-popup-options textarea[name="personalizedText"]').length) {
            $('.product-popup-options textarea[name="personalizedText"]').val('');
        }
        $('.product-popup-options input[name="personalizedImageFromWeb"]').click();
    });

    $(document).on('change', '.product-popup-options #personalizedImage', function (event) {
        let files = event.target.files;
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');
        uploadImage(
            files,
            {prefix: 'catalog'},
            function(data) {
                $('.product-popup-options #personalizedImageRoute').val(data.fileInfo.file);
                $('.product-popup-options #personalizedImageText').val(data.fileInfo.originalName);
                $('.product-popup-options input[name="personalizedImageFromWeb"]').prop('checked', false);
                if ($('.product-popup-options input[name="customPriceIncrement"]').length) {
                    $('.product-popup-options input[name="customPriceIncrement"]').prop('checked', true);
                }
                cartFunctions.displayPersonalizedFields();
                if (!isNewSearcherPopUp) {
                    if ($('.modify-product').length > 0) {
                        trackEvent('ModifyProduct', 'Upload', 'ok');
                    } else {
                        trackEvent('ProductList', 'Upload', 'ok');
                    }
                }
            },
            function(data) {
                popupInfoDialog(data.error);
                trackEvent('ProductList', 'Upload', 'error');
            }
        );
    });

    $(document).on('change', '.product-popup-options input[name="personalizedImageFromWeb"]', function (event) {
        if ($(this).is(':checked')) {
            $('.product-popup-options #personalizedImage').val(null);
            $('.product-popup-options #personalizedImageRoute').val('');
            $('.product-popup-options #personalizedImageText').val('');
            if ($('.product-popup-options input[name="customPriceIncrement"]').length) {
                if (!$('.product-popup-options #personalizedText').length || !$('.product-popup-options #personalizedText').val().length) {
                    $('.product-popup-options input[name="customPriceIncrement"]').prop('checked', false);
                }
            }
            cartFunctions.displayPersonalizedFields();
        } else {
            if ($('.product-popup-options input[name="customPriceIncrement"]').length) {
                $('.product-popup-options input[name="customPriceIncrement"]').prop('checked', true);
            }
        }
    });

    $(document).on('change', '.product-popup-options input[name="complement"],' +
        '.product-popup-options input[class="cross-selling-select-product"]', function (event) {
        cartFunctions.updatePrice();
    });

    //T-SHIRTS DUO

    $('body').on('change', '.product-popup-options .multi-product-options .modelGender-option input[type=radio]', function () {
        let productOptionIndex = $(this).closest('.multi-product-options').data('productIndex').toString();
        $('.product-popup-options #modelGender'+productOptionIndex).val($(this).val());
        loadModelGenderOptions(cartFunctions, productOptionIndex);
    });

    $('body').on('change', '.product-popup-options .multi-product-options .model-option input[type=radio]', function () {
        let productIndex = $(this).closest('.multi-product-options').data('productIndex').toString();
        $('.product-popup-options #model'+productIndex).val($(this).val());
        loadModelSizesAndColors(cartFunctions, productIndex);
    });

    $('body').on('change', '.product-popup-options .multi-product-options .personalizedImageFromWebMultiProduct', function (event) {
        if ($(this).is(':checked')) {
            let productIndex = $(this).closest('.multi-product-options').data('productIndex').toString();
            $('.product-popup-options #personalizedImage'+productIndex).val(null);
            $('.product-popup-options #personalizedImageRoute'+productIndex).val('');
            $('.product-popup-options #personalizedImageText'+productIndex).val('');
            displayPersonalizedFieldsMultiProduct();
        }
    });

    $('body').on('change', '.product-popup-options .multi-product-options .personalizedImageMultiProduct', function (event) {
        let files = event.target.files;
        let imageInput = $(this);
        let isNewSearcherPopUp = $(this).closest('.product-popup-options').hasClass('new-searcher-popup');

        uploadImage(
            files,
            {prefix: 'catalog'},
            function(data) {
                let productIndex = imageInput.closest('.multi-product-options').data('productIndex').toString();
                $('.product-popup-options #personalizedImageRoute'+productIndex).val(data.fileInfo.file);
                $('.product-popup-options #personalizedImageText'+productIndex).val(data.fileInfo.originalName);
                $('.product-popup-options #personalizedImageFromWeb'+productIndex).prop('checked', false);
                displayPersonalizedFieldsMultiProduct();
                if (!isNewSearcherPopUp) {
                    if ($('.modify-product').length > 0) {
                        trackEvent('ModifyProduct', 'Upload', 'ok');
                    } else {
                        trackEvent('ProductList', 'Upload', 'ok');
                    }
                }
            },
            function(data) {
                popupInfoDialog(data.error);
                trackEvent('ProductList', 'Upload', 'error');
            }
        );
    });

    $('body').on('change', '.product-popup-options .multi-product-options .model-sizes-option input[type=radio]', function () {
        let productIndex = $(this).closest('.multi-product-options').data('productIndex').toString();
        $('.product-popup-options #modelSize'+productIndex).val($(this).val());
    });

    $(document).on('submit','#form-popup-options',function(event){
        event.preventDefault();
    })

    $(document).on('change', '.modify-product #preview-size', function () {
        trackEvent('ModifyProduct', 'Size', $(this).text());
    });

    $(document).on('change', '.modify-product input[name="orientation"]', function () {
        trackEvent('ModifyProduct', 'Orientation', $(this).val());
    });

    $(document).on('change', '.modify-product input[name="material-canvas"]', function () {
        let material = $('#add-to-cart-material input[name="material-canvas"]:checked').val();
        if (material) {
            let sizes = $('.product-popup-options #add-to-cart-size input[name="add-to-cart-size"]');
            sizes.each(function () {
                let size = $(this);
                size.prop('checked', false).trigger("change");
                if (size.data('material-canvas') != material) {
                    size.parent().css('display', 'none');
                } else {
                    size.parent().css('display', 'block');
                }
            });
        }
    });
    
    let timeOutPersonalizedText;
    $(document).on('keydown', '.modify-product input[name="personalizedText"]', function () {
        clearTimeout(timeOutPersonalizedText);
        timeOutPersonalizedText = setTimeout(function(){
            trackEvent('ModifyProduct', 'PersonalizedText', '');
        }, 1000);
    });

    $(document).on('change', '.modify-product input[name=pack]', function () {
        trackEvent('ModifyProduct', 'Size', $(this).data('name'));
    });

    $('body').on('click', '.product-popup-options .effect-option', function() {
        let radio = $(this).find('input[name="add-to-cart-effect"]');
        radio.prop("checked", true);
        $("#add-to-cart-effect #effect").val(radio.val());
        let name = $(this).find('.effect-name').text();
        $('#add-to-cart-preview-effect').html(name);
        let compositionId = $(".product-popup-options input[name=add-to-cart-size]:checked").data('compositionId');
        setPreviewImage(true, compositionId);
    });

    /*########################################################################
    //
    //    BLIND EVENTS
    //
    ########################################################################*/
    $('body').on('click', '.product-popup-options .sizeWidth-option, .product-popup-options .sizeHeight-option', function() {
        let radio = '';
        let input = '';
        if($(this).hasClass('sizeWidth-option')) {
            radio = $(this).find('input[name="sizeWidth"]');
            input = $('.product-popup-options #custom-width');
        } else if($(this).hasClass('sizeHeight-option')) {
            radio = $(this).find('input[name="sizeHeight"]');
            input = $('.product-popup-options #custom-height');
        }
        radio.prop("checked", true);
        let measure = radio.val();
        //input.val(measure).trigger('change').removeClass('empty');
        input.val(measure).trigger('change');
        $('.product-popup-options .roundToMultipleOfFive').addClass('empty').css('color', '#fff');
    });

    $('body').on('click', '.product-popup-options .roundToMultipleOfFive', function() {
        $('.product-popup-options input[name="sizeWidth"]').prop("checked", false);
        $('.product-popup-options input[name="sizeHeight"]').prop("checked", false);
        $(this).val('');
        $(this).removeClass('empty').css('color', '#000');
        $('#multiple-of-five-alert').hide();
    });

    $('body').on('keydown', '.product-popup-options .roundToMultipleOfFive', function() {
        $('.product-popup-options input[name="sizeWidth"]').prop("checked", false);
        $('.product-popup-options input[name="sizeHeight"]').prop("checked", false);
        //$('.product-popup-options .roundToMultipleOfFive').removeClass('empty').css('color', '#000');
    });

    $('body').on('click', '.product-popup-options .tissue-option', function() {
        $('input[name="tissue"]').prop('checked', false);
        let radio = $(this).find('input[type="radio"]');
        radio.prop('checked', true);
        $(".product-popup-options #tissue").val(radio.val());
        let name = $(this).find('.tissue-name').html();
        $("#add-to-cart-preview-tissue").html(name);
        if ($('#custom-width').val() && $('#custom-height').val()) {
            cartFunctions.updatePrice();
        }
    });

    $('body').on('click', '.product-popup-options .mechanism-option', function() {
        $('input[name="mechanism"]').prop('checked', false);
        let radio = $(this).find('input[name="mechanism"]');
        radio.prop('checked', true);
        let mechanism = radio.val();
        $(".product-popup-options #mechanism").val(mechanism);
        let body = $(this).find('.mechanism-body');
        $('.mechanism-body').not(body).slideUp(function() {
            body.slideDown();
        });
        $('input[name="controlQuantity"]').removeClass('active');
        $(this).find('input[name="controlQuantity"]:enabled').addClass('active');
        $('input[name="chargerQuantity"]').removeClass('active');
        $(this).find('input[name="chargerQuantity"]:enabled').addClass('active');

        if ($('#custom-width').val() && $('#custom-height').val()) {
            cartFunctions.updatePrice();
        }

        let name = $(this).find('.mechanism-name').html();
        $("#add-to-cart-preview-mechanism").html(name);
        let hasChain = $(this).data('hasChain');
        if (hasChain) {
            let chainPosition = $("input[name='chainPosition']:checked").val();
            $("#chain-position").val(chainPosition);
            $('.control-warning').hide();
        } else {
            $("#chain-position").val('');
            $('.control-warning').show();
        }
    });

    $('body').on('click', '.product-popup-options .drop-option', function() {
        $('input[name="drop"]').prop('checked', false);
        let radio = $(this).find('input[type="radio"]');
        radio.prop('checked', true);
        $(".product-popup-options #drop").val(radio.val());
        let name = $(this).find('.drop-name').text();
        $("#add-to-cart-preview-drop").html(name);
    });

    $('body').on('click', '.product-popup-options .chain-position-option', function(e) {
        e.stopPropagation();
        $('input[name="chainPosition"]').prop('checked', false);
        let radio = $(this).find('input[name="chainPosition"]');
        radio.prop("checked", true);
        $('.product-popup-options #chain-position').val(radio.val());
    });

    $('body').on('click', '.product-popup-options .support-color-option', function() {
        $('input[name="support-color"]').prop('checked', false);
        let radio = $(this).find('input[type="radio"]');
        radio.prop('checked', true);
        $(".product-popup-options #support-color").val(radio.val());
        let name = $(this).find('.support-color-name').text();
        $("#add-to-cart-preview-support-color").html(name);
        cartFunctions.updatePrice();
    });

    $('body').on('change', '.product-popup-options input[name="vinyl-rug-format"]', function() {
        $("#add-to-cart-preview-format").html($(this).data('name'));
    });

    // CONTROLS

    $('body').on("click", ".product-popup-options .qty-btn-removeControl", function () {
        let btn = $(this);
        let input = btn.siblings('.qty-display');
        let quantityControls = parseInt(input.val()) - 1;
        validateQuantityControls(input, quantityControls);
        trackEvent('ProductPage', 'ControlQuantity', 'add/remove');
    });

    $('body').on("click", ".product-popup-options .qty-btn-addControl", function () {
        let btn = $(this);
        let input = btn.siblings('.qty-display');
        let quantityControls = parseInt(input.val()) + 1;
        validateQuantityControls(input, quantityControls);
        trackEvent('ProductList', 'ControlQuantity', 'add/remove');
    });

    roundToMultipleOfFive();

    // CHARGERS

    $('body').on("click", ".product-popup-options .qty-btn-removeCharger", function () {
        let btn = $(this);
        let input = btn.siblings('.qty-display');
        let quantityChargers = parseInt(input.val()) - 1;
        validateQuantityChargers(input, quantityChargers);
        trackEvent('ProductPage', 'ChargerQuantity', 'add/remove');
    });

    $('body').on("click", ".product-popup-options .qty-btn-addCharger", function () {
        let btn = $(this);
        let input = btn.siblings('.qty-display');
        let quantityChargers = parseInt(input.val()) + 1;
        validateQuantityChargers(input, quantityChargers);
        trackEvent('ProductList', 'ChargerQuantity', 'add/remove');
    });
});

function validateQuantityControls(input,quantityControls) {
    quantityControls = +quantityControls || 0; //make sure it's a number
    let btnRemove = input.siblings('.qty-btn-removeControl');
    if (quantityControls === 0) {
        btnRemove.attr('disabled', true);
    } else {
        btnRemove.attr('disabled', false);
    }
    input.val(quantityControls).trigger('change');
};

function validateQuantityChargers(input,quantityChargers) {
    quantityChargers = +quantityChargers || 0; //make sure it's a number
    let btnRemove = input.siblings('.qty-btn-removeCharger');
    if (quantityChargers === 0) {
        btnRemove.attr('disabled', true);
    } else {
        btnRemove.attr('disabled', false);
    }
    input.val(quantityChargers).trigger('change');
};

export function setCartFunctions(container, eventPage)
{
    cartFunctions = new addToCartFunctions(container, eventPage);
}

export function updateView() {
    if ($('#t-shirt-product').length) {
        $('.product-popup-options .material-color-selected').click();
        cartFunctions.updatePrice();
    }
}

function loadModelGenderOptions(cartFunctions, productIndex) {
    $('.btn-add-to-cart').attr('disabled', 'disabled');
    let selectedModelGender = $('.product-popup-options #modelGender'+productIndex).val();
    if (selectedModelGender.length) {
        $.post(
            '/ajax/product/load-model-gender-options',
            {
                'gender': selectedModelGender,
                'productId': $('.product-popup-options #productId').val(),
                'prefix': 'popup-',
                'productIndex': productIndex
            },
            function (data) {
                $('.product-popup-options #product-model'+productIndex).html(data.models);
                $('#model'+productIndex).val('');
                if (data.materialColors.length) {
                    $('.product-popup-options #product-material-color'+productIndex).html(data.materialColors);
                    $('.product-popup-options #materialColor'+productIndex).val('');
                }
                $('.product-popup-options #add-to-cart-material-color .cart-value-selected').html('');
                $('.product-popup-options #product-model-sizes'+productIndex).html(data.modelSizes);
                $('.product-popup-options #modelSize'+productIndex).val('');
                $('.product-popup-options #product-model'+productIndex+' input[name=popup-model-option'+productIndex+']').first().click();
                $('.product-popup-options #add-to-cart-model.panel-closed .panel-dropdown-header').click();
            },
            'json'
        );
    }
}

function loadModelSizesAndColors(cartFunctions, productIndex) {
    $('.btn-add-to-cart').attr('disabled', 'disabled');
    let selectedModel = $('.product-popup-options #model'+productIndex).val();
    if (selectedModel.length) {
        $.post(
            '/ajax/product/load-model-sizes-and-colors',
            {
                'modelId': selectedModel,
                'productId': $('.product-popup-options #productId').val(),
                'prefix': 'popup-',
                'productIndex': productIndex
            },
            function (data) {
                if (data.materialColors.length) {
                    $('.product-popup-options #product-material-color'+productIndex).html(data.materialColors);
                    $('.product-popup-options #materialColor'+productIndex).val('');
                }
                $('.product-popup-options #add-to-cart-material-color .cart-value-selected').html('');
                $('.product-popup-options #product-model-sizes'+productIndex).html(data.modelSizes);
                $('.product-popup-options #modelSize'+productIndex).val('');
                $('.product-popup-options #product-options .material-color-selected').click();
                $('.product-popup-options .multi-product-options .material-color-selected').click();
                setSelectedMaterialColor(productIndex);
                cartFunctions.updatePrice();
            },
            'json'
        );
    }
}

function setSelectedMaterialColor(productIndex) {
    let selectedColor = $('#product-options .material-color-selected');
    let selectedColorMultiProduct = $(".multi-product-options .material-color-selected");
    if (selectedColor.length) {
        $('.product-popup-options #materialColor').val(selectedColor.data('id'));
    }

    if (selectedColorMultiProduct.length) {
        selectedColorMultiProduct.each(function () {
            $('.product-popup-options #materialColor'+productIndex).val($(this).data('id'));
        })
    }
}

function updatePanel($panel, $selectedContainer, close) {
    let closePanel = close || false;
    $panel.find('.slider-section-title .cart-value-selected').remove();
    $panel.find('.slider-section-title').append($selectedContainer);
    if (closePanel) {
        $panel.find('.slider-section-title').click();
    }
}

function updateClosePanel($panel, $selectedContainer) {
    updatePanel($panel, $selectedContainer, true);
}

function closePanel($panel) {
    $panel.find('.slider-section-title').click();
}

function displayPersonalizedFieldsMultiProduct() {
    $(".personalizedTextMultiProduct").each(function() {
        let previewModel = $(this).closest('.panel-dropdown-body').prev('.panel-dropdown-header').find('.preview-selected-option');
        previewModel.html($(this).val()).show();
    })

    $(".personalizedImageMultiProduct").each(function() {
        let personalizedImageField = $(this).next("input[type='hidden']");
        if (personalizedImageField.length && personalizedImageField.val()) {
            $(this).closest('.panel-dropdown-body').prev('.panel-dropdown-header').find('.preview-selected-option').children(".glyphicon-camera").show();
        } else {
            $(this).closest('.panel-dropdown-body').prev('.panel-dropdown-header').find('.preview-selected-option').children(".glyphicon-camera").hide();
        }
    })
}

let ajaxPreviewImage;

function setPreviewImage(makePhotoMontage = false, compositionId = null) {
    let productId = $('#add-to-cart-options #productId').val();
    let effect = $(".product-popup-options #effect").val();
    if (ajaxPreviewImage) {
        ajaxPreviewImage.abort();
    }
    ajaxPreviewImage =
        $.post(
            '/ajax/product/preview-image',
            {
                productId: productId,
                makePhotoMontage: makePhotoMontage,
                effect: effect,
                compositionId: compositionId
            },
            function (data) {
                let url = new URL(data.img);
                let imgPath = url.pathname;
                $(".product-popup-options #personalizedImageRoute").val(imgPath);
            },
            'json'
        );
}
