/**
 * @desc onVisible(document.querySelector("#myElement"), () => console.log("it's visible"));
 * @param element
 * @param callback
 */
export function onVisible(elements, callback, threshold) {
    let selectedElements = [].slice.call(elements);
    selectedElements.forEach(function(element) {
        new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    callback(element);
                    observer.disconnect();
                }
            });
        }, {'threshold': threshold||1}).observe(element);
    });
}

export function checkElementIsVisibleInViewport(element)
{
    let rect = element.getBoundingClientRect();
    let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);

    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

export function checkElementIsVisibleInParent(element)
{
    let rect = element.getBoundingClientRect();
    let parent = element.parentElement;
    let parentRect = parent.getBoundingClientRect();

    return !(rect.bottom < parentRect.top || rect.top > parentRect.bottom);
}