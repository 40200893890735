import {waitingDialog} from "../../library/waiting-dialog";
import {popupInfoDialog} from "../../library/info-dialog";
let maxFileSize = 15;

export function uploadImage(files, options, success, error) {
    if (!files.length) {
        return;
    }
    let data = new FormData();
    let filesSize = 0;
    $.each(files, function(key, value) {
        filesSize += value.size;
        data.append(key, value);
    });
    filesSize = Math.round((filesSize / 1024 / 1024) * 100) / 100;
    if (filesSize > maxFileSize) {
        return showMaxSizeError(filesSize, maxFileSize);
    }
    $.each(options, function(key, value) {
        data.append(key, value);
    });
    waitingDialog.show();

    $.ajax({
        type: 'POST',
        url: '/ajax/upload/upload-image',
        data: data,
        cache: false,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: function(data) {
            if (data.done) {
                typeof success === 'function' && success(data);
            } else {
                typeof error === 'function' && error(data);
            }
        },
        error: function(data) {
            typeof error === 'function' && error(data);
        },
        complete: function () {
            waitingDialog.hide();
        }
    });
}

export function uploadImageBase64(imgBase64, options, success, error) {
    let data = new FormData();
    data.append('imgBase64', imgBase64);
    $.each(options, function(key, value) {
        data.append(key, value);
    });
    waitingDialog.show();

    $.ajax({
        type: 'POST',
        url: '/ajax/upload/upload-image-base64',
        data: data,
        cache: false,
        dataType: 'json',
        contentType: false,
        processData: false,
        success: function(data) {
            if (data.done) {
                typeof success === 'function' && success(data);
            } else {
                typeof error === 'function' && error(data);
            }
        },
        error: function(data) {
            typeof error === 'function' && error(data);
        },
        complete: function () {
            waitingDialog.hide();
        }
    });
}

export function uploadImageSVG(imgSVG, img, options, success, error) {
    let data = new FormData();
    data.append('imgSVG', imgSVG);
    data.append('imgBase64', img);
    $.each(options, function(key, value) {
        data.append(key, value);
    });
    waitingDialog.show();

    $.ajax({
        type: 'POST',
        url: '/ajax/upload/upload-image-svg',
        data: data,
        cache: false,
        dataType: 'json',
        contentType: false,
        processData: false,
        success: function(data) {
            if (data.done) {
                typeof success === 'function' && success(data);
            } else {
                typeof error === 'function' && error(data);
            }
        },
        error: function(data) {
            typeof error === 'function' && error(data);
        },
        complete: function () {
            waitingDialog.hide();
        }
    });
}

function showMaxSizeError(size, maxSize) {
    waitingDialog.show();
    $.post(
        '/ajax/upload/get-max-size-error',
        {
            size: size,
            maxSize: maxSize
        },
        function (data) {
            popupInfoDialog(data.message);
            waitingDialog.hide();
        }
    );
}