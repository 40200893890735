import insightsClient from 'search-insights';
import {getCid} from "./analytics";

const appId = document.querySelector('#al-app').value;
const apiKey = document.querySelector('#al-ak').value;
const indexName = document.querySelector('#al-pi').value;
const userToken = getCid().replace('.', '-');

//insightsClient('init', {appId, apiKey, useCookie: true});

export function productClick(eventPrefix, eventName, id, queryId, position)
{
    if (true || !indexName) {
        return;
    }
    if (queryId) {
        insightsClient('clickedObjectIDsAfterSearch', {
            userToken: userToken,
            eventName: eventPrefix + ':' + eventName,
            index: indexName,
            queryID: queryId,
            objectIDs: [id],
            positions: [position],
        });
    } else {
        insightsClient('clickedObjectIDs', {
            userToken: userToken,
            eventName: eventPrefix + ':' + eventName,
            index: indexName,
            objectIDs: [id],
        });
    }
}

export function productAddToCart(eventPrefix, eventName, id, queryId)
{
    if (true || !indexName) {
        return;
    }
    if (queryId) {
        insightsClient('convertedObjectIDsAfterSearch', {
            userToken: userToken,
            eventName: eventPrefix + ':' + eventName,
            index: indexName,
            queryID: queryId,
            objectIDs: [id],
        });
    } else {
        insightsClient('convertedObjectIDs', {
            userToken: userToken,
            eventName: eventPrefix + ':' + eventName,
            index: indexName,
            objectIDs: [id],
        });
    }
}

export function viewProducts(eventPrefix, eventName, ids)
{
    if (true || !indexName) {
        return;
    }
    insightsClient('viewedObjectIDs', {
        userToken: userToken,
        eventName: eventPrefix + ':' + eventName,
        index: indexName,
        objectIDs: ids,
    });
}