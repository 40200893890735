let variableName = '_tv_last_viewed_products';
let maxProducts = 20;

export function addLastViewedProduct(reference)
{
    let lastViewedProducts = JSON.parse(localStorage.getItem(variableName)) || [];
    if (!lastViewedProducts.includes(reference)) lastViewedProducts.push(reference);
    if (lastViewedProducts.length > maxProducts) lastViewedProducts.shift();
    localStorage.setItem(variableName, JSON.stringify(lastViewedProducts));
}

export function getLastViewedProducts()
{
    return JSON.parse(localStorage.getItem(variableName));
}