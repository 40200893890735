export function loadCompositionsOption() {
    if ($('.img-composition').length > 0) {
        let compositionContainerHeight = $('.img-composition-container').height();
        let radioHeight = $('#add-to-cart-size .radio-option').height();
        $('#add-to-cart-size .radio-option').css('margin-top', (compositionContainerHeight - radioHeight) / 2).show();
        let priceHeight = $('#add-to-cart-size .size-prices').height();
        //if ($('#add-to-cart-size .size-prices').css('position') === 'absolute') {
            $('#add-to-cart-size .size-prices').css('margin-top', (compositionContainerHeight - priceHeight) / 2);
        //}
        $('#add-to-cart-size .size-offers').css('margin-top', (compositionContainerHeight - priceHeight) / 2);
        //css('transform', 'none');
        $('.img-composition').css('margin-top', (compositionContainerHeight - priceHeight) / 3);

        $(".img-composition").one("load", function () {
            let marginTop = null;
            let imgHeight = $(this).height();
            marginTop = (compositionContainerHeight - imgHeight) / 2;
            if (marginTop != null) {
                $(this).css('margin-top', marginTop);
                $(this).closest('label').find('.size-name').css('margin-top', marginTop);
                if ($(this).closest('label').find('.size-prices').css('position') !== 'absolute') {
                    $(this).closest('label').find('.size-prices').css('margin-top', '-10px');
                }
                //$(this).closest('label').find('.size-prices').css('margin-top', marginTop);
                //$(this).closest('label').find('.size-offers').css('margin-top', marginTop);
            }
        }).each(function () {
            if (this.complete) {
                $(this).load(); // For jQuery < 3.0
                // $(this).trigger('load'); // For jQuery >= 3.0
            }
        });
    }
}

export function loadCompositionsOptionModal() {
    if ($('.product-popup-options .img-composition').length > 0) {
        let compositionContainerHeight = $('.product-popup-options .img-composition-container').height();
        let radioHeight = $('.product-popup-options #add-to-cart-size .radio-option').height();
        $('.product-popup-options #add-to-cart-size .radio-option').css('margin-top', (compositionContainerHeight - radioHeight) / 2).show();
        let priceHeight = $('.product-popup-options #add-to-cart-size .size-prices').height();
        //if ($('.product-popup-options #add-to-cart-size .size-prices').css('position') === 'absolute') {
            $('.product-popup-options #add-to-cart-size .size-prices').css('margin-top', ((compositionContainerHeight - priceHeight) / 2) + 3);
        //}
        $('.product-popup-options #add-to-cart-size .size-name').css('margin-top', (compositionContainerHeight - priceHeight) / 2);
        $('.product-popup-options #add-to-cart-size .size-offers').css('margin-top', (compositionContainerHeight - priceHeight) / 2).
        css('transform', 'none');
        //$('.product-popup-options .img-composition').css('margin-top', (compositionContainerHeight - priceHeight) / 3);

        $(".product-popup-options .img-composition").one("load", function () {
            let marginTop = null;
            let imgHeight = $(this).height();
            marginTop = (compositionContainerHeight - imgHeight) / 2;
            if (marginTop != null) {
                if ($(this).closest('label').find('.size-prices').css('position') !== 'absolute') {
                    $(this).closest('label').find('.size-prices').css('margin-top', '-5px');
                }
                //$(this).css('margin-top', marginTop);
                //$(this).closest('label').find('.size-name').css('margin-top', marginTop);
                //$(this).closest('label').find('.size-prices').css('margin-top', marginTop);
                //$(this).closest('label').find('.size-offers').css('margin-top', marginTop);
            }
        }).each(function () {
            if (this.complete) {
                $(this).load(); // For jQuery < 3.0
                // $(this).trigger('load'); // For jQuery >= 3.0
            }
        });
    }
}