import {initializeDescription} from "./description";
import {removeOverlay, removeUpperOverlay} from "../../library/overlay";

$(document).ready(function() {
    $(document).on('click', '.slide-up-slider.open .slider-section-title', function() {
        $(this).next('.slider-section-content').slideToggle('slow');
        $(this).toggleClass('expanded');
    });

    $(document).on('click', '.btnHidePopup', function(){
        hidePopup();
        //trackEvent('Results', 'ShowStickers');
    });
});

export function hidePopup() {
    if ($('.slide-up-slider').length) {
        togglePopup($('.slide-up-slider'));
        initializeDescription();
    }
}

function togglePopup($element){
    removeUpperOverlay();
    $element.toggleClass('open');
    setTimeout(function() {
        $('#add-to-cart-options').html('');
    }, 300);
}
