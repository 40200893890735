export function addOverlay() {
    let body = $('body');
    if (!body.hasClass('overlay-added')) {
        body.append('<div class="overlay"></div>');
        body.addClass('overlay-added');
    }
}
export function removeOverlay() {
    $('.overlay').remove();
    $('body').removeClass('overlay-added');
}
export function addUpperOverlay() {
    let body = $('body');
    if (!body.hasClass('.upper-overlay-added')) {
        body.append('<div class="upper-overlay"></div>');
        body.addClass('second-overlay-added');
    }
}
export function removeUpperOverlay() {
    $('.upper-overlay').remove();
    $('body').removeClass('upper-overlay-added');
}
export function addSoftOverlay() {
    let body = $('body');
    if (!body.hasClass('soft-overlay-added')) {
        body.append('<div class="soft-overlay"></div>');
        body.addClass('soft-overlay-added');
    }
}
export function removeSoftOverlay() {
    $('.soft-overlay').remove();
    $('body').removeClass('soft-overlay-added');
}
export function addSearchOverlay(device) {
    let body = $('body');
    let header = $('#header-mobile');
    if (device == 'desktop') {
        header = $('#header-menu');
    }
    if (!body.hasClass('search-overlay-added')) {
        header.prepend('<div class="search-overlay"></div>');
        body.addClass('search-overlay-added');
    }
}
export function removeSearchOverlay() {
    $('.search-overlay').remove();
    $('body').removeClass('search-overlay-added');
}